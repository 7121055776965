<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nuevo Motivo</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <NovedadesMotivoForm></NovedadesMotivoForm>
    </div>
  </v-container>
</template>
<script>
import NovedadesMotivoForm from "../components/NovedadesMotivoForm.vue";

export default {
  name: "NovedadesMotivoAlta",
  components: {
    NovedadesMotivoForm,
  },
  data() {
    return {};
  },
  created() {},
};
</script>
